import axios from "axios";
import React, { useContext, useReducer } from "react";
import { toast } from "react-toastify";
import dataReducer from "../reducer/dataReducer";
import {
  HANDLE_BUTTON_CLICK,
  HANDLE_MODAL,
  HANDLE_SCHEDULED_CONTENTS_REMOVE,
  HANDLE_SCHEDULED_CONTENTS,
  HANDLE_FILE_SELECT,
  HANDLE_FILE_UPLOAD,
  HANDLE_INPUT,
  HANDLE_INPUT_COUNT,
  HANDLE_SUBMIT,
  HANDLE_COPY,
  HANDLE_LOGOUT,
  HANDLE_LOADING,
  HANDLE_LENGTH,
  HANDLE_CONTENTS,
} from "../utils/action";
import { notify } from "../utils/notifications";

const initialState = {
  url: "",
  text: "📍",
  textCount: 0,
  activeTwitter: true,
  activeTelegram: true,
  activeSchedule: false,
  selectedDateTime: "",
  selectedFile: "",
  loading: false,
  isModalOpen: false,
  scheduledContents: [],
  contents: [],
  length: 0,
};

const DataContext = React.createContext();
export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  const handleCopy = (data) => {
    dispatch({ type: HANDLE_COPY, payload: data });
  };

  const handleCancel = async (uuid) => {
    dispatch({ type: HANDLE_LOADING });
    const access_token = localStorage.getItem("access_token");
    const data = { uuid: uuid };
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/firlatici/cancel-firlat`,
      withCredentials: true,
      headers: {
        "secret-key": process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      notify(200, response.data.message);
      dispatch({ type: HANDLE_SCHEDULED_CONTENTS_REMOVE, payload: uuid });
      dispatch({ type: HANDLE_LOADING });
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        dispatch({ type: HANDLE_LOGOUT });
        window.location.reload();
      }
      notify(
        400,
        error?.response?.data?.message ?? "Failed to cancel the content"
      );
      dispatch({ type: HANDLE_LOADING });
    }
  };

  const handleModal = () => {
    dispatch({ type: HANDLE_MODAL });
  };
  const handleFileUpload = async (e) => {
    dispatch({ type: HANDLE_LOADING });
    dispatch({ type: HANDLE_FILE_SELECT, payload: e.target.value });
    const access_token = localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    const id = toast.loading("Görsel yükleniyor...");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/upload`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        timeout: 30000,
      };
      const response = await axios(config);
      if (response.status === 201) {
        toast.update(id, {
          render: "Görsel başarıyla yüklendi!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
        dispatch({
          type: HANDLE_FILE_UPLOAD,
          payload: response.data.data.fileName,
        });
      }
      if (response.status === 401) {
        notify(200, "Erişim yok, çıkış yapıldı!");
        dispatch({ type: HANDLE_LOGOUT });
        window.location.reload();
      }
      dispatch({ type: HANDLE_LOADING });
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        window.location.reload();
        dispatch({ type: HANDLE_LOGOUT });
      }
      toast.update(id, {
        render: "Görsel yüklenemedi, tekrar deneyiniz!",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      dispatch({ type: HANDLE_LOADING });
    }
  };
  const handleButtonClick = (event) => {
    dispatch({ type: HANDLE_BUTTON_CLICK, payload: event });
  };
  const handleInput = (event) => {
    dispatch({ type: HANDLE_INPUT, payload: event });
  };
  const handleInputCount = (count) => {
    dispatch({ type: HANDLE_INPUT_COUNT, payload: count });
  };
  const handleFirlat = async () => {
    dispatch({ type: HANDLE_LOADING });
    const access_token = localStorage.getItem("access_token");
    const user = localStorage.getItem("email");
    const id = toast.loading("İçerik gönderiliyor...");
    try {
      const data = {
        content: state.text,
        is_twitter: state.activeTwitter,
        is_telegram: state.activeTelegram,
        is_scheduled: state.activeSchedule,
        image_url: state.url,
        created_by: user,
        scheduled_date: state.activeSchedule ? state.selectedDateTime : "",
        status: 1,
      };
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/firlatici/firlat`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: data,
      };
      if (state.activeTelegram || state.activeTwitter) {
        const response = await axios(config);
        dispatch({ type: HANDLE_SUBMIT });
        const twitterSuccess =
          response.data.data.twitter.is_asked ===
            response.data.data.twitter.status ||
          !response.data.data.twitter.is_asked;
        const telegramSuccess =
          response.data.data.telegram.is_asked ===
            response.data.data.telegram.status ||
          !response.data.data.telegram.is_asked;

        const message =
          state.selectedDateTime !== ""
            ? "İçerik başarıyla planlandı!"
            : twitterSuccess && telegramSuccess
            ? "İçerik başarıyla gönderildi!"
            : `İçerik gönderilemedi: ${
                response.data.data.twitter.is_asked &&
                !response.data.data.twitter.status
                  ? "Twitter: " + response.data.data.twitter.error
                  : response.data.data.twitter.status
                  ? "Twitter gönderildi"
                  : ""
              } - ${
                response.data.data.telegram.is_asked &&
                !response.data.data.telegram.status
                  ? "Telegram: " + response.data.data.telegram.error
                  : response.data.data.telegram.status
                  ? "Telegram gönderildi"
                  : ""
              }`;
        const type = twitterSuccess && telegramSuccess ? "success" : "error";
        const autoClose = twitterSuccess && telegramSuccess ? 5000 : 10000;
        toast.update(id, {
          render: message,
          type: type,
          isLoading: false,
          autoClose: autoClose,
        });
      }
      dispatch({ type: HANDLE_LOADING });
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        window.location.reload();
        dispatch({ type: HANDLE_LOGOUT });
      } else {
        toast.update(id, {
          render:
            state.selectedDateTime !== ""
              ? "İçerik planlanamadı, lütfen tekrar deneyiniz!"
              : "İçerik gönderilemedi, lütfen tekrar deneyiniz!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }
    }
    dispatch({ type: HANDLE_LOADING });
    dispatch({ type: HANDLE_SUBMIT });
  };
  const handleScheduledContents = (data) => {
    dispatch({ type: HANDLE_SCHEDULED_CONTENTS, payload: data });
  };
  const handleLength = (length) => {
    dispatch({ type: HANDLE_LENGTH, payload: length });
  };
  const handleLoaderData = (data) => {
    dispatch({ type: HANDLE_CONTENTS, payload: data });
  };
  const handleContents = async (page) => {
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_API}/api/firlatici/contents?id=${page}`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
      };
      const response = await axios(config);
      dispatch({ type: HANDLE_CONTENTS, payload: response.data.data.data });
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("access_token");
        window.location.reload();
      }
      return error;
    }
  };
  return (
    <DataContext.Provider
      value={{
        ...state,
        handleCancel,
        handleScheduledContents,
        handleCopy,
        handleModal,
        handleFirlat,
        handleFileUpload,
        handleButtonClick,
        handleInput,
        handleInputCount,
        handleLength,
        handleContents,
        handleLoaderData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

// make sure use
export const useDataContext = () => {
  return useContext(DataContext);
};
